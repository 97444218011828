import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withKeys as _withKeys, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/images/pc/login-logo.png'
import _imports_1 from '@/assets/images/logo.png'
import _imports_2 from '@/assets/images/warn.svg'


const _hoisted_1 = {
  key: 0,
  alt: "pc"
}
const _hoisted_2 = { class: "login-background" }
const _hoisted_3 = { class: "login-center" }
const _hoisted_4 = { class: "login-body" }
const _hoisted_5 = { class: "id-box" }
const _hoisted_6 = { class: "typing" }
const _hoisted_7 = { class: "typing-box id" }
const _hoisted_8 = { class: "typing-box" }
const _hoisted_9 = { class: "flex check" }
const _hoisted_10 = { class: "form-check form-check-inline" }
const _hoisted_11 = {
  key: 0,
  class: "form-check form-check-inline"
}
const _hoisted_12 = {
  key: 1,
  alt: "mobile"
}
const _hoisted_13 = { class: "login wrap" }
const _hoisted_14 = {
  key: 0,
  class: "first"
}
const _hoisted_15 = {
  key: 1,
  class: "typing"
}
const _hoisted_16 = { class: "typing-box id" }
const _hoisted_17 = { class: "typing-box" }
const _hoisted_18 = { class: "flex check" }
const _hoisted_19 = { class: "form-check form-check-inline" }
const _hoisted_20 = {
  key: 0,
  class: "form-check form-check-inline"
}

import useBaseComponent from "@/baseComponent";
import { NetworkService, Emit_LoginFailed, Emit_ResetMoveToLogin } from "@/network"
// import { SettingsModel, SettingsExModel } from '../db';
import SettingsService from '../services/SettingsService';
import * as Constant from '../constants/Global';
// import * as AccPwd from '../constants/AccPwd';
import { DEVICE_TYPE_MOBILE } from "./../constants/Global";


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const { store, onMounted, onUnmounted, reactive } = useBaseComponent();
let state = reactive({
    first: false,

    isShowPopup: false,
    popupText: "",

    id: '',
    pw: '',

    isChecked1: false,
    isChecked2: false,
    isFirstLogin: true, // 재접속 처리용
    loginState: 0,

    timerCheckboxUpdated: null as any,
});

onMounted( async () => {
    // const isMobileTestOnly = window.location.protocol.toLowerCase().startsWith('https:');
    // if(isMobileTestOnly) {
    //     await isPc();
    //     await isTablet();
    // }
    store.CommonState.isLoginPage = true;
    NetworkService.emitter.on(Emit_LoginFailed, event_LoginFailed );
    NetworkService.emitter.on(Emit_ResetMoveToLogin, event_ResetMoveToLogin );

    store.setLastViewPage('');

    if(store.LoginPageState.errorState && store.LoginPageState.errorState === 1 && store.LoginPageState.errorMessage) {
        // 로컬스토리지 캐싱
        resetAndReloadPage();
    }
    else if(store.LoginPageState.isReloadRequired) {
        // 메모리 캐싱
        resetAndReloadPage();
    }
    else {
        await loadPriorConfig();
    }
})
onUnmounted(()=> {
    store.CommonState.isLoginPage = false;
    NetworkService.emitter.off(Emit_LoginFailed, event_LoginFailed);
    NetworkService.emitter.off(Emit_ResetMoveToLogin, event_ResetMoveToLogin );
    if(state.timerCheckboxUpdated){
        clearTimeout(state.timerCheckboxUpdated);
    }
})

const onCheckboxUpdated = () => {
    state.timerCheckboxUpdated = setTimeout(function() {
        if(state.isChecked1 === false) {
            state.id = '';
            state.pw = '';
            store.updateLoginPassword('');
        }
        saveCurrentLoginInfo();
    }, 100);
}

const saveCurrentLoginInfo = async () => { store.saveLoginPageInfo(state.id, state.isChecked1, state.isChecked2); }
const loadPriorConfig = async () => {

    state.id = store.LoginPageState.id;
    state.isChecked1 = store.LoginPageState.isChecked1;
    state.isChecked2 = store.LoginPageState.isChecked2;
    state.loginState = store.LoginPageState.loginState;

    state.pw = await SettingsService.getUserPwd();
}

const login = () => {

    // 임시 모바일만 지원
    // const isMobileTestOnly = window.location.protocol.toLowerCase() === 'https:';
    // if(isMobileTestOnly) {
    //     if(isPc() || isTablet())
    //         return;
    // }

    if(NetworkService._socketState != Constant.SOCKET_STATE_OPEN) {
        store.setVisibleDialog({ message: '네트워크 연결이 원활하지 않습니다' });
        return;
    }

    // 아이디 소문자
    if(state.id)
        state.id = state.id.toLowerCase();

    const loginInfo = {
        userID: state.id,
        userPwd: state.pw,
        bFirstLogin: state.isFirstLogin ? 1 : 0,
        bMock: state.isChecked2 ? 1 : 0,
        eventFrom: 'loginPage',
    }

    store.SettingsState.loginID = state.id;

    // 계좌 팝업
    store.setSettingAccountPopupState(1);

    SettingsService.getUserId().then(priorId => {
        if(priorId && priorId == state.id) {
            store.initializeSettings(false);
            store.initializeSettingsEx(false);
        }
        else {
            // 다른 아이디로 로그인, 정보 초기화
            store.initializeSettings(true);
            store.initializeSettingsEx(true);
            store.initAccPwd();
        }

        store.LoginPageState.loginFrom = "loginPage";

        // 기존 저장로직용
        store.updateLoginId(state.id);
        store.updateLoginPassword(state.pw);

        // UI 선택용
        saveCurrentLoginInfo();

        setTimeout(() => {
            NetworkService.send.login(loginInfo);
        }, 100);
    })
}


const close = (popupType: string) => {
    switch (popupType) {
        case "PopupConfirm":
            state.isShowPopup = false;
            break;
    }
};

const event_LoginFailed = (params: any) => { 
    resetAndReloadPage(); 
};

const event_ResetMoveToLogin = (params: any) => { 
    resetAndReloadPage(); 
}

const resetAndReloadPage = () => {
    // 이전에 공용팝업에서 처리 된 이후 콜백임
    // 오류 초기화, 새로고침
    store.updateLoginPageState(0);
    store.updateLoginPageError(0, '');
    location.reload();
}

// const isPc = () => {
//     const ua = window.navigator.userAgent.toString().toLowerCase() || '';
//     if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
//         const message = 'PC 버전은 현재 지원하지 않습니다'
//         store.setVisibleDialog({ message: message,});
//         return true;
//     } 
//     return false;
// }

// const isTablet =()=> {
//     const ua = window.navigator.userAgent.toString().toLowerCase() || '';
//     if (ua.match(/ipad/) || (ua.match(/android/) && !ua.match(/mobi|mini|fennec/))) {
//         const message = 'PC 버전은 현재 지원하지 않습니다'
//         store.setVisibleDialog({ message: message,});
//         return true;
//     }
//     return false;
// }

// const isMobile = () => {
//     const ua = window.navigator.userAgent.toString().toLowerCase() || '';
//     if (ua.match(/ip(hone|od)|android.+mobile|windows (ce|phone)|blackberry|bb10|symbian|webos|firefox.+fennec|opera m(ob|in)i|polaris|iemobile|lgtelecom|nokia|sonyericsson|dolfin|uzard|natebrowser|ktf;|skt;/)) {
//         return true;
//     } else {
//         return false;
//     }
// }


return (_ctx: any,_cache: any) => {
  const _component_PopupConfirm = _resolveComponent("PopupConfirm")!

  return (_unref(store).SystemState._deviceType !== _unref(DEVICE_TYPE_MOBILE))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"login-head\"><div class=\"item-box\"><p class=\"title\">DOW JOENS</p><p class=\"price\">34,811</p><p class=\"number\"><i class=\"icon-up\"></i><span class=\"text red\">250.00 </span><span class=\"text red\">+0.16%</span></p></div><div class=\"item-box\"><p class=\"title\">NASDAQ</p><p class=\"price\">13,693.75</p><p class=\"number\"><i class=\"icon-up\"></i><span class=\"text red\">1.00 </span><span class=\"text red\">+0.01%</span></p></div><div class=\"item-box\"><p class=\"title\">S&amp;P 500</p><p class=\"price\">4,218.00</p><p class=\"number\"><i class=\"icon-up\"></i><span class=\"text red\">4.75 </span><span class=\"text red\">+0.07%</span></p></div><div class=\"item-box\"><p class=\"title\">KOSPI</p><p class=\"price\">427,36</p><p class=\"number\"><i class=\"icon-down\"></i><span class=\"text blue\">1.41 </span><span class=\"text blue\">-0.19%</span></p></div><div class=\"item-box\"><p class=\"title\">EURO FX</p><p class=\"price\">1,22910</p><p class=\"number\"><i class=\"icon-down\"></i><span class=\"text blue\">0.76</span><span class=\"text blue\">-0.02%</span></p></div></div>", 1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "logo-box" }, [
                _createElementVNode("div", null, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "",
                    style: {"width":"270px","margin":"0 auto","display":"block"}
                  })
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[20] || (_cache[20] = _createElementVNode("p", { style: {"color":"#fff","font-size":"12px","margin-bottom":"6px"} }, "아이디", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      placeholder: "프랜드 고객 아이디",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).id) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(state).id]
                    ])
                  ]),
                  _cache[21] || (_cache[21] = _createElementVNode("p", { style: {"color":"#fff","font-size":"12px","margin-bottom":"6px"} }, "비밀번호", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      type: "password",
                      placeholder: "비밀번호를 입력해주세요",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).pw) = $event)),
                      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (login()), ["enter"]))
                    }, null, 544), [
                      [_vModelText, _unref(state).pw]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "checkbox",
                        name: "inlineRadioOptions",
                        id: "inlineRadio1",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).isChecked1) = $event)),
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (onCheckboxUpdated()))
                      }, null, 544), [
                        [_vModelCheckbox, _unref(state).isChecked1]
                      ]),
                      _cache[18] || (_cache[18] = _createElementVNode("label", {
                        class: "form-check-label",
                        for: "inlineRadio1"
                      }, "아이디 저장", -1))
                    ]),
                    (_unref(store).SystemState._appMode !== 2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-check-input",
                            type: "checkbox",
                            name: "inlineRadioOptions",
                            id: "inlineRadio2",
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(state).isChecked2) = $event)),
                            onChange: _cache[6] || (_cache[6] = ($event: any) => (onCheckboxUpdated()))
                          }, null, 544), [
                            [_vModelCheckbox, _unref(state).isChecked2]
                          ]),
                          _cache[19] || (_cache[19] = _createElementVNode("label", {
                            class: "form-check-label",
                            for: "inlineRadio2"
                          }, "모의투자 접속", -1))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("button", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (login())),
                    style: {"height":"44px","font-size":"18px","font-weight":"bold","background":"#007bb2","box-shadow":"none","margin-bottom":"0"}
                  }, "로그인")
                ])
              ])
            ])
          ]),
          _createVNode(_component_PopupConfirm, {
            isShowPopup: _unref(state).isShowPopup,
            text: _unref(state).popupText,
            onClose: _cache[8] || (_cache[8] = ($event: any) => (close('PopupConfirm')))
          }, null, 8, ["isShowPopup", "text"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          (_unref(state).first)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[24] || (_cache[24] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "로고",
                  class: "logo"
                }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _cache[27] || (_cache[27] = _createElementVNode("img", {
                  src: _imports_1,
                  alt: "로고",
                  class: "logo"
                }, null, -1)),
                _createElementVNode("div", _hoisted_16, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: "프랜드 고객 아이디",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(state).id) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(state).id]
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    placeholder: "접속 비밀번호 입력",
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(state).pw) = $event)),
                    onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (login()), ["enter"]))
                  }, null, 544), [
                    [_vModelText, _unref(state).pw]
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      name: "inlineRadioOptions",
                      id: "inlineRadio1",
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(state).isChecked1) = $event)),
                      onChange: _cache[13] || (_cache[13] = ($event: any) => (onCheckboxUpdated()))
                    }, null, 544), [
                      [_vModelCheckbox, _unref(state).isChecked1]
                    ]),
                    _cache[25] || (_cache[25] = _createElementVNode("label", {
                      class: "form-check-label",
                      for: "inlineRadio1"
                    }, "아이디 저장", -1))
                  ]),
                  (_unref(store).SystemState._appMode !== 2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-check-input",
                          type: "checkbox",
                          name: "inlineRadioOptions",
                          id: "inlineRadio2",
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(state).isChecked2) = $event)),
                          onChange: _cache[15] || (_cache[15] = ($event: any) => (onCheckboxUpdated()))
                        }, null, 544), [
                          [_vModelCheckbox, _unref(state).isChecked2]
                        ]),
                        _cache[26] || (_cache[26] = _createElementVNode("label", {
                          class: "form-check-label",
                          for: "inlineRadio2"
                        }, "모의투자 접속", -1))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("button", {
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (login()))
                }, "로그인"),
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "flex warn" }, [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: "접속 비밀번호를 입력하십시오"
                  }),
                  _createElementVNode("p", null, "접속 비밀번호를 입력하십시오")
                ], -1))
              ])),
          _createVNode(_component_PopupConfirm, {
            isShowPopup: _unref(state).isShowPopup,
            text: _unref(state).popupText,
            onClose: _cache[17] || (_cache[17] = ($event: any) => (close('PopupConfirm')))
          }, null, 8, ["isShowPopup", "text"])
        ])
      ]))
}
}

})